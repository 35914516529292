import axios from "axios";

import swal from "sweetalert2"
//this is the URL
// const url = "https://z9ido0fqf3.execute-api.ap-southeast-1.amazonaws.com/prod";
// const url = "https://tpwa213z15.execute-api.ap-southeast-1.amazonaws.com/prod";
// const url = "https://cfdu750xwj.execute-api.ap-southeast-2.amazonaws.com/prod";
// const url = "https://cfdu750xwj.execute-api.ap-southeast-2.amazonaws.com/prod";


// **********************************//
// SEND EMAIL USING PHP MAILER API  //
//***********************************//

export default {
  async send(from,senderEmail,to, cc = [], message) {
    const data = {
      from: from,
      senderEmail:senderEmail,
      to: to,
      cc: cc || [],
      subject: "HRD INQUIRY EMAIL NOTIFICATION",
      message: message,
      // attachment: attachment || [],
    }; 
    console.log(data)
    axios
    axios({
      method: 'POST',
      // url:'https://ihs.hrd-s.com/hrdinquiry_email/api/mail/store',
      url:'https://ihs.hrd-s.com/hrdinquiry_email/api/mail/store',
      // url: 'http://sys-com_svr/api-email-sending-hrd-inquiry/api/mail/store',
      data: data
    })
      .then((res) => {
        if(res.data == "Done!"){
          swal.fire({
            icon: 'success',
            title: 'Email Sent',
            allowOutsideClick: false,
            showConfirmButton: false,
            timer: 2000
          })
          
        }
        else{
          swal.fire({
            icon: 'error',
            title: 'Email Server Error Please Send Manual',
            showConfirmButton: false,
            timer: 2000
          })
        }
    }
      );
  },

  /**
   * API FOR SENDING CORRECTION REQUEST EMAIL
   * @param {String} ConfirmationID ConfirmationID Email
   * @param {String} Status Status Email
   * @param {String} Department Department Email
   * @param {String} SenderID SenderID Email
   * @param {String} due TypeOfConfirmation Email
   * @param {String} from Sender Email
   * @param {String} senderEmail
   * @param {String} to Receipient Email
   * @param {String} cc cc Receipient
   * @param {String} message Body of the email
   * @param {String} customerCode CustomerCode
   * @param {String} subject Subject of the email
   * @param {Array} attachment Attachment of Email
   */
  async sendCorrection(
    ConfirmationID,
    Status,
    Department,
    SenderID,
    due,
    from,
    senderEmail,
    to,
    cc = [],
    message,
    customerCode,
    subject,
    // attachment
  ) {
    
    const data = {
      ConfirmationID:ConfirmationID,
      Status:Status,
      Department:Department,
      SenderID:SenderID,
      due:due,
      from: from,
      senderEmail:senderEmail,
      to: to,
      cc: cc || [],
      // subject: "HRD INQUIRY EMAIL NOTIFICATION",
      subject: subject,
      message: message,
      // attachment: attachment || [],
    };
    console.log(data,"EmailJS")
    //This code have a problem, sending in live mail.
    // axios
    //   .post(`${url}/api/SendEmail`, { data: data })
    //   // .post(`${url}/api/Nodemailer`, { data: data })
    //   .then((res) => {
    //     console.log(res.data)
    //     // console.log(this.data.subject)
    //     if(res.data.statusCode == 400){
    //       swal.fire({
    //         position: 'top',
    //         icon: 'error',
    //         title: 'The e-mail exceeds more than 10MB. Please resend manually from live mail.',
    //         showConfirmButton: false,
    //         timer: 6500
    //       })
          
    //   }
    //   else{
    //     swal.fire({
    //       position: 'top',
    //       icon: 'success',
    //       title: 'The File succesfully uploaded',
    //       showConfirmButton: false,
    //       timer: 3000
    //     })
    //   }
    //   });


    // > Test LAravel - API

    // axios({
    //   method: 'POST',
    //   url: 'http://127.0.0.1:8000/api/mail/store',
    //   data: data
    // }).then(() => {
    //   alert('Done!');
    // }).catch(() => {
    //   alert('Error!');
    // })

    // > Actual Api
    axios({
      method: 'POST',
      // url:'https://ihs.hrd-s.com/hrdinquiry_email/api/mail/store',
      url: 'http://10.169.140.114/api-email-sending-hrd-inquiry/api/mail/store',
      // url: 'http://sys-com_svr/api-email-sending-hrd-inquiry/api/mail/store',
      data: data
    }).then((res) => {
      // alert('Done')
      // if(res.status != 200){
      //   swal.fire({
      //     title: 'Something went wrong',
      //     text: " please try again",
      //     icon: 'error',
      //   })
      // }
      // else{
      console.log(res)
      if(res.data == "Done!"){
      swal.fire({
        icon: 'success',
        title: 'Email Sent',
        allowOutsideClick: false,
        showConfirmButton: false,
        timer: 2000
      })
      
    }
    else{
      swal.fire({
        icon: 'error',
        title: 'Email Server Error Please Send Manual',
        showConfirmButton: false,
        timer: 2000
      })
    }
      // setTimeout(() => {
      //   window.location.reload();
      // }, 3500);
    // }
    }).catch((res) => {
      console.log(res)
      swal.fire({
        title: 'New User Detected! click "OK"',
        allowOutsideClick: false,
        text: "and follow the instruction",
        icon: 'warning',
        confirmButtonText: 'OK'
      }).then((result) => {
        if (result.isConfirmed) {
          window.open("https://s3.us-east-2.amazonaws.com/inquiry.hrd-s.com/img/HRD+INQUIRY2.pdf");
        }
      })
    })
  },
};
