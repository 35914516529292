import axios from "axios";
import keys from "../../alert";

const httpRequest = {
  //***************************************//
  //  CHECK DRAFT REQUEST IN DRAFT TABLE  //
  //**************************************//
  checkDraft(UserID, DraftName) {
    return Promise.resolve(
      axios.get(
        `${keys.auth.BaseUrl}/CheckDraft`,
        {
          params: {
            UserID: UserID,
            DraftName: DraftName,
          },
        },
        keys.auth.apiKey
      )
    );
  },
  //***************************************//
  //  GET DRAFT REQUEST IN DRAFT TABLE    //
  //**************************************//
  Get() {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getDrafts`, keys.auth.apiKey)
    );
  },
  GetSpecific(id) {
    return Promise.resolve(
      axios.get(`${keys.auth.BaseUrl}/getUserDraft/${id}`, keys.auth.apiKey)
    );
  },
  //***************************************//
  //    INSERT REQUEST IN DRAFT TABLE     //
  //**************************************//
  Insert(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertDraft`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  InsertConDraft(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/insertDraftReply`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  Update(id) {
    return Promise.resolve(
      axios.put(`${keys.auth.BaseUrl}/updateDraft/${id}`, keys.auth.apiKey)
    );
  },
  //************************************************//
  //    CHECK FOR IMAGE REQUEST IN DRAFT TABLE     //
  //***********************************************//
  CheckImage(data) {
    return Promise.resolve(
      axios.post(
        `${keys.auth.BaseUrl}/deleteImageFromDraft`,
        { data: data },
        keys.auth.apiKey
      )
    );
  },
  //**************************************//
  //    DELETE REQUEST IN DRAFT TABLE     //
  //*************************************//
  Delete(id) {
    return Promise.resolve(
      axios.delete(`${keys.auth.BaseUrl}/deleteDrafts/${id}`, keys.auth.apiKey)
    );
  },
};

export default httpRequest;
