import AWS from "aws-sdk";
import moment from "moment";
// import store from "@/store";
const uniqid = require("uniqid");

AWS.config.update({
  region: "us-east-2",
  accessKeyId: process.env.VUE_APP_ACESSKEYID,
  secretAccessKey: process.env.VUE_APP_SECRET_KEY,
});

let s3 = new AWS.S3({ apiVersion: "2006-03-01" });

// ALL AWS ATTACHMENT RELATED FUNCTION CONSIST OF OBJECT PARAMETER
// *BUCKET: FOR S3 BUCKET NAME
// *KEY: KEYNAME IN S3 EX:"Attachment/2021/01/01/test.jpg" SAVE OR GET ATTACHMENT
// *BODY: THE FILES THAT WILL SAVE IN S3
// *COPYSOURCE: WHERE THE FILE ORIGINATE IN S3

const attachment = {
  /**
   * FOR AWS UPLOADING MULTIPLE FILES IN BUCKET
   * 
   * @param {String} InqCode upload path
   * @param {String} path upload path
   * @param {Array} files files to upload
   * @param {Function} callback getting name for saving database
   * @param {Function} keyCallback get key after uploading
   */

   async upload(InqCode, path, files, callback, keyCallback) {
    let newName = []; // for keyName
    let promise = []; // Promise upload
    console.log(InqCode);
    console.log(files, "35 attachment");
    
    for (let x in files) {
      console.log(files[x]);
      const generateID = uniqid(); // Generate Unique ID
      const dateToday = moment().format("YYYY/MM/DD"); // GET DATE TODAY
      console.log(InqCode);
  
      if (InqCode == "009A") {
        const changeDate = moment().format("YYYYMMDDhhmmss");
        const changeName = files[x].name.split(".");
        const renamed = changeName[0] + "-" + changeDate + "-Z--HRD PROCESS-.pdf";
        
        if (files[x].name.includes("HRD PROCESS")) {
          const hrdProcessRename = changeName[0].split("HRD PROCESS");
          const finalHrdProcessRename = hrdProcessRename[0] + changeDate + "-Z--HRD PROCESS-.pdf";
          newName.push(`${generateID}_${finalHrdProcessRename}`);
          
          // Declare uploadParams with let to ensure proper scope
          let uploadParams = {
            Bucket: "inquiry.files",
            Key: `${path}/${dateToday}/${generateID}_${finalHrdProcessRename}`,
            Body: files[x],
          };
          
          // PUSH UPLOADING S3 OBJECT 123
          promise.push(uploadS3(uploadParams));
        } else {
          newName.push(`${generateID}_${renamed}`);
          
          // Declare uploadParams with let to ensure proper scope
          let uploadParams = {
            Bucket: "inquiry.files",
            Key: `${path}/${dateToday}/${generateID}_${renamed}`,
            Body: files[x],
          };
          
          // PUSH UPLOADING S3 OBJECT
          promise.push(uploadS3(uploadParams));
        }
      } else {
        newName.push(`${generateID}_${files[x].name}`);
        
        // Declare uploadParams with let to ensure proper scope
        let uploadParams = {
          Bucket: "inquiry.files",
          Key: `${path}/${dateToday}/${generateID}_${files[x].name}`,
          Body: files[x],
        };
        
        // PUSH UPLOADING S3 OBJECT
        promise.push(uploadS3(uploadParams));
      }
    }
    
    // GET ALL KEYNAME. NO NEED TO WAIT TO FINISH UPLOAD
    callback(newName);
    
    // UPLOAD S3 FUNCTION
    function uploadS3(val) {
      return s3.upload(val).promise();
    }
  
    // CALLBACK AFTER SENDING ALL ATTACHMENT
    Promise.all(promise).then(() => keyCallback(newName));
  },

  /**
   * FOR AWS COPY FILES BUCKET
   * @param {String} locationPath path to be copy
   * @param {String} destinationPath destination to paste
   * @param {Array} files files to copy
   */

  async copy(locationPath, destinationPath, files) {
    for (let x in files) {
      let copyParams = {
        Bucket: "inquiry.files",
        CopySource: `inquiry.files/${locationPath}/${files[x].OriginalName}`,
        Key: `${destinationPath}/${files[x].OriginalName}`,
      };

      s3.copyObject(copyParams, (err) => {
        if (err) throw err;
      });
    }
  },

  /**
   * FOR AWS DELETING MULTIPLE FILE LOCATION IN BUCKET
   * @param {String} location Path location
   * @param {Array} files files to delete
   */

  async delete(location, files) {
    for (let x in files) {
      let deleteParams = {
        Bucket: "inquiry.files",
        Key: `${location}/${files[x].OriginalName}`,
      };

      s3.deleteObject(deleteParams, (err) => {
        if (err) throw err;
      });
    }
  },

  /**
   * FOR AWS DELETING FILES IN BUCKET
   * @param {Array} files files to delete
   */

  singleDelete(files) {
    for (let x in files) {
      let deleteParams = {
        Bucket: "inquiry.files",
        Key: `${files[x].pathName}`,
      };

      s3.deleteObject(deleteParams, (err) => {
        if (err) throw err;
      });
    }
  },

  /**
   * FOR AWS DOWNLOADING FILES IN BUCKET
   * @param {String} location Path location
   * @param {Array} file file to download
   */

  download(location, file) {
    console.log(location,file)
    const downloadParams = {
      Bucket: "inquiry.files",
      Key: `${location}/${file}`,
      ResponseContentDisposition: "attachment; filename="+ encodeURIComponent(`${file}`,"UTF-8"),
    };
    console.log(downloadParams)

    s3.getSignedUrl("getObject", downloadParams, (err, data) => {
      console.log(data,"176")
      if (err) throw err;
      window.open(data, "_blank");
    });
  },

  /**
   *
   * @param {Array} imgData Name of image
   * @returns converted images to filetype
   */

  convertToFileType(imgData) {
    return new Promise((resolve, reject) => {
      let images = [];

      for (let img of imgData) {
        const downloadParams = {
          Bucket: "inquiry.files",
          Key: img,
          // ResponseContentDisposition: `attachment; filename="1ksb4z5jl_TEST document.pdf"`,
        };
        s3.getObject(downloadParams, (err, result) => {
          if (err) return reject(err);

          let blob = new Blob([new Uint8Array(result.Body)], {
            type: "application/pdf",
          });
          const fileType = new File([blob], img.split("/")[4].split("_")[1], {
            type: "application/pdf",
          });
          images.push(fileType);
        });
      }
      resolve(images);
    });
  },


  // Function to open a file using a pre-signed URL
  openFileParams(location, file, contentType) {
    const openFileParams = {
      Bucket: 'inquiry.files',
      Key: `${location}/${file}`,
      ResponseContentType: contentType, // Corrected parameter name
      ResponseContentDisposition: `inline; filename="${encodeURIComponent(file)}"`
    };
    console.log(openFileParams);
    // Generate a pre-signed URL
    const signedUrl = s3.getSignedUrl('getObject', openFileParams);
    // Open the file in a new tab/window
    window.open(signedUrl, '_blank');
  }

};

export default attachment;
