import axios from "axios";
import keys from "../../alert";

const baseUrl = keys.auth.BaseUrl;
const apikey = keys.auth.apiKey;

export default {
  Post(data) {
    //*************************************//
    //           POST REQUEST              //
    //*************************************//

    return Promise.resolve(
      axios.post(`${baseUrl}/insertInquiryStatus`, { data: data }, apikey)
    );
  },
  //*************************************//
  //           PUT REQUEST              //
  //*************************************//
  Update(data) {
    return Promise.resolve(
      axios.put(`${baseUrl}/updateInquiryStatus`, { data: data }, apikey)
    );
  },
    //*************************************//
  //           PUT REQUEST              //
  //*************************************//
  UpdateFinish(data) {
    return Promise.resolve(
      axios.put(`${baseUrl}/updateInquiryStatusToFinish`, { data: data }, apikey)
    );
  },
  UpdateMultipleFinish(data) {
    return Promise.resolve(
      axios.post(`${baseUrl}/updateMultipleInquiryStatusToFinish`, { data: data }, apikey)
    );
  },
  //*************************************//
  //           GET REQUEST               //
  //*************************************//
  // GetCount() {
  //   return Promise.resolve(axios.get(`${baseUrl}/getInquiryStatusCount`), apikey);
  // },
  Get(offset) {
    return Promise.resolve(axios.get(`${baseUrl}/getInquiryStatus/${offset}`), apikey);
  },
  //***********************************//
  //      GET SPECIFIC REQUEST         //
  //***********************************//

  GetSpecific(confirmationID) {
    return Promise.resolve(
      axios.get(`${baseUrl}/getInquiryStatus/${confirmationID}`, apikey)
    );
  },
  //****************************************//
  //      GET SPECIFIC TYPE REQUEST         //
  //****************************************//

  GetSpecificType(Type) {
    return Promise.resolve(
      axios.get(`${baseUrl}/getSpecificInquiryStatus/${Type}`, apikey)
    );
  },
  // // COUNT SPECIFIC
  GetSpecificTypeCount(Type) {
    return Promise.resolve(
      axios.get(`${baseUrl}/getSpecificInquiryStatusCount/${Type}`, apikey)
    );
  },
   //****************************************//
  //      GET SPECIFIC DueDate REQUEST         //
  //****************************************//

  GetSpecificDueDate(DueDate) {
    return Promise.resolve(
      axios.get(`${baseUrl}/getSpecificInquiryStatusDueDate/${DueDate}`, apikey)
    );
  },
  GetDataExtraction() {
    return Promise.resolve(
      axios.get(`${baseUrl}/getDataExtraction/2022-05-01/2022-05-31`, apikey)
    );
  },
};


