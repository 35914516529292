const moment = require("moment");
const DevType = process.env.NODE_ENV;
//***************************//
//       EMAIL TEMPLATE     1 //
//***************************//
exports.correctionRequestTemplate = (data,senderName) => {
  const area_note = `  <br>
  <b style="color:red;">※トライアルエリアのみ対象</b>

下記URLをクリックしてシステムからの返信を受け取ってください。
(For more details please click the system link.)
    <a href="${
      DevType == "development"
        ? "http://localhost:8080"
        : "https://inquiry.hrd-s.com"
    }/received/reply/${
      data.ConfirmationID
}"> HRD Inquiry System </a>
`

  return `
  HRD問い合わせシステムからの通知メッセージです。
(You have just received a new message from HRD Inquiry.)
 
送信者(Sender)：${senderName}
件名(Subject)：${data.Subject}
お客様コード(Customer Code)：${data.CustomerCode}
お問い合わせ内容(Detail)：
================================================================================
${data.Content}
================================================================================ 
下記URLをクリックしていただき、システムより返信してください。
  (For more details please click the system link.)
   <a href="${
                DevType == "development"
                  ? "http://localhost:8080"
                  : "https://inquiry.hrd-s.com"
              }/received/reply/${
              data.ConfirmationID
              }"> HRD Inquiry System </a>
   
  本メールは通知用メールのため、返信はできませんのでご了承ください。
  (This is a system generated email. Please do not reply to this message.)
  
  ${data.isPlanner ? area_note:''}
  `

  
    



  // return `New Message Recieved
  //            <a href="${
  //             DevType == "development"
  //               ? "http://localhost:8080"
  //               : "https://inquiry.hrd-s.com"
  //           }/received/reply/${
  //           data.ConfirmationID
  //           }"> HRD Inquiry System </a>
  //       `;
};


exports.Template = (data) => {
  console.log(data,"66")

  return `
  HRD問い合わせシステムからの通知メッセージです。
(You have just received a new message from HRD Inquiry.)
 
送信者(Sender)：${data.SenderName}
問い合わせ内容(Inquiry Type)：${data.InquiryType}
問い合わせ項目(Inquiry Item)：${data.InquiryItem}
お客様コード(Customer Code)：${data.CustomerCode}
書類番号(Documents Number)：${data.RuleBookNumber}
お問い合わせ内容(Detail)：
================================================================================
${data.MessageContent}
================================================================================ 
下記URLをクリックしていただき、システムより返信してください。
(For more details please click the system link.)
 <a href="${
              DevType == "development"
                ? "http://localhost:8080"
                : "http://sys-com_svr/hrdinquiry"
            }/received/reply/${
            data.ConfirmationID
            }"> HRD Inquiry System </a>
 
本メールは通知用メールのため、返信はできませんのでご了承ください。
(This is a system generated email. Please do not reply to this message.)
`

  




  // return `New Message Recieved
  //            <a href="${
  //             DevType == "development"
  //               ? "http://localhost:8080"
  //               : "https://inquiry.hrd-s.com"
  //           }/received/reply/${
  //           data.ConfirmationID
  //           }"> HRD Inquiry System </a>
  //       `;
};
// exports.Template = (data) => {
//   if (typeof data != "object") return;

//   const initial = "0000000";
//   const cutLength = data.dataLength[0].DataLength;
//   const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
//     0,
//     -cutLength
//   )}${data.dataLength[0].DataLength}`;
//   return `
//           To ${data.Receiver} 様<br> <br>

//           問い合わせ番号(inquiry reference number)：${referenceNumber}<br>
// ${
//   data.RuleBookNumber
//     ? `書類番号(Document Number) : ${data.RuleBookNumber}<br><br>`
//     : ``
// }

//           返信期限(Due date) : ${data.Due}<br><br>

//           問い合わせ内容(Content):<br>${data.Content}<br><br>

//           下記システムより返信してください。
// 本メールは送信専用アドレスのため、返信することができません。
// <br>
//              <span style="font-size: 12px;color:grey">(You can reply in this email addres. This email is a send-only and cannot be replied)</span><br>
//              <a href="${
//               DevType == "development"
//                 ? "http://localhost:8080"
//                 : "https://inquiry.hrd-s.com"
//             }/received/reply/${
//             data.ConfirmationID
//             }"> HRD Inquiry System </a>
//         `;
// };




exports.TemplateCorrectionRequestReply = (data,subject,SenderEmail) => {
  console.log(data,SenderEmail,subject)
  if (typeof data != "object") return;
  const initial = "0000000";
  const cutLength = data.dataLength[0].DataLength;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data.dataLength[0].DataLength}`;
  //  To ${data.Receiver} 様
  return `<br><br><br>----------------------------------------------------<br>ご担当者様<br><br><br><br>問い合わせ番号(inquiry reference number)：${referenceNumber}<br><br><br><br>返信内容(Reply Content):<br><br>${data.inquiryCode?`${data.CustomerCode}<br><br>`:""}${data.Content}<br>----------------------------------------------------<br>`;

};























exports.TemplateCorrectionRequest = (data) => {
  if (typeof data != "object") return;
  const initial = "0000000";
  const cutLength = data.dataLength[0].DataLength;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data.dataLength[0].DataLength}`;
  //  To ${data.Receiver} 様
  return ` 
          ご担当者様<br> <br>

           問い合わせ番号(inquiry reference number)：${referenceNumber}<br><br>
        

          問い合わせ内容(Content):
          <br>
          ${
            data.inquiryCode
              ? `${data.CustomerCode}<br><br>`
              : ""
          }
          ${data.Content}

          

        `;
        

//<span style="font-size: 12px;color:grey">(You can reply in this link. This email is a send-only and cannot be replied)</span><br>
//<a href="mailto:${SenderEmail}?cc=${ToCc}&subject=Re:${subject}&In-Reply-To=${SenderEmail}&body=${ContentReply}"">Reply to Email</a>

        
//         <a href="${
//           DevType == "development"
//             ? "http://localhost:8080"
//             : "https://inquiry.hrd-s.com"
//         }/received/reply/${
// data.ConfirmationID
// }"> HRD Inquiry System </a>
};
//  <br><br>
// 宜しくお願い致します。<br><br>${data.signature}
// ${data.signature ? `宜しくお願い致します。<br><br>${data.signature}` : ""}
//${data.customerName}


exports.TemplateCorrectionRequestWithSystemLink = (data) => {
  if (typeof data != "object") return;
  const initial = "0000000";
  const cutLength = data.dataLength[0].DataLength;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data.dataLength[0].DataLength}`;
  //  To ${data.Receiver} 様
  console.log(referenceNumber)
  return ` 
          You Received New Message!

          <br>
          <span style="font-size: 12px;color:grey">(You can reply in this link. This email is a send-only and cannot be replied)</span><br>
          <a href="${
                      DevType == "development"
                        ? "http://localhost:8080"
                        : "https://inquiry.hrd-s.com"
                    }/received/reply/${
            data.ConfirmationID
            }"> HRD Inquiry System </a>  
        `;
        
//
/* <a href="mailto:${SenderEmail}?cc=${ToCc}&subject=Re:${subject}&In-Reply-To=${SenderEmail}&body=${ContentReply}"">Reply to Email</a> */

};






















exports.TemplateCorrectionRequestResend = (data) => {
  // console.log(data)
  if (typeof data != "object") return;
  const initial = "0000000";
  const cutLength = data[0].id;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data[0].id}`;
  
  // console.log(referenceNumber,"83")
  return `
          To ${data[0].To} 様<br> <br>

          問い合わせ番号(inquiry reference number)：${referenceNumber}<br><br>
          

          問い合わせ内容(Content):
          <br>
          
          ${data[0].MessageContent}
          <br>
        `;  
};
exports.TemplateCorrectionRequestResendCAD = (data) => {
  // console.log(data)
  if (typeof data != "object") return;
  const initial = "0000000";
  const cutLength = data[0].id;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data[0].id}`;
  
  // console.log(referenceNumber,"83")
  return `
          To ${data[0].To} 様<br> <br>
          
          問い合わせ番号(inquiry reference number)：${referenceNumber}<br><br>
          
          問い合わせ内容(Content):
          <br>
          
          ${data[0].MessageContent}
          <br>
          
        `;  
};


exports.notificationTemplate = async (data) => {
  const initial = "0000000"; // INITIAL REFNUMBER
  const cutLength = data.ReferenceNumber.toString(); //CUTTING LENGTH BASED ON REFNUMBER
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data.ReferenceNumber}`; // FORMAT REFERENCE NUMBER

  let senderReply = data.ReplyContent[0]; // FOR THE CONTENT OF SENDER REPLY
  const lastReply = data.ReplyContent[data.ReplyContent.length - 1]; //FOR THE CONTENT OF RECEIVER REPLY
    console.log(senderReply,lastReply,referenceNumber)
  return `
     
  HRD問い合わせシステムからの通知メッセージです。
  (You have just received a new reply from HRD Inquiry.)
  
  お客様コード(Customer Code)：${senderReply.CustomerCode}
  件名です(Subject):${lastReply.Subject}
  返信期限(Reply Deadline):${lastReply.DueDate}
  お問い合わせ内容(Detail)：
================================================================================
${lastReply.MessageContent}
================================================================================ 

  下記URLをクリックしていただき、システムより返信してください。
  (For more details please click the system link.)
   <a href="${
                DevType == "development"
                  ? "http://localhost:8080"
                  : "http://sys-com_svr/hrdinquiry"
              }/received/reply/${
                lastReply.ConfirmationID
              }"> HRD Inquiry System </a>
   
  本メールは通知用メールのため、返信はできませんのでご了承ください。
  (This is a system generated email. Please do not reply to this message.)
    `;
};

    
// exports.notificationTemplate = async (data) => {
//   // console.log(data)
//   const initial = "0000000"; // INITIAL REFNUMBER
//   const cutLength = data.ReferenceNumber.toString(); //CUTTING LENGTH BASED ON REFNUMBER
//   const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
//     0,
//     -cutLength
//   )}${data.ReferenceNumber}`; // FORMAT REFERENCE NUMBER

//   let senderReply = data.ReplyContent[0]; // FOR THE CONTENT OF SENDER REPLY
//   const lastReply = data.ReplyContent[data.ReplyContent.length - 1]; //FOR THE CONTENT OF RECEIVER REPLY

//   return `
//       問い合わせ番号(inquiry reference number)：${referenceNumber}<br> 
//       書類番号(Document Number) : ${data.DocumentNumber || ""} <br>
//       返信期限(Due date) : ${data.Due}<br><br>

//       ${moment(lastReply.DateCreated).format("YYYY/MM/DD")}送信<br>
//       To ${senderReply.SenderName}<br><br>

//       問い合わせ内容(Content):<br>${lastReply.MessageContent}<br><br>

//       From ${lastReply.SenderName}<br><br>

//       ----------------------------------------------------<br>
//       To ${lastReply.SenderName}<br><br>
        
//       返信内容(Reply Content):<br>${senderReply.MessageContent}<br><br>

//       From ${senderReply.SenderName}<br>
//       ----------------------------------------------------<br><br>

//       本メールの宛先に返信してください。<br>
//       <a href="${
//         DevType == "development"
//           ? "http://localhost:8080"
//           : "https://inquiry.hrd-s.com"
//       }/received/reply/${
// data.ConfirmationID
// }"> HRD Inquiry System </a><br><br>
//       宜しくお願い致します。<br><br>
//       <h4> Trial Only </h4>
//       `;
// };

exports.PreviewTemplate = (information, body) => {
  const template = `
        <b>Information</b><br><br>
        Sender Name: ${information.SenderName}<br>
        Sender Email: ${information.SenderEmail}<br><br>
        Receiver Name: ${information.ReceiverName}<br>
        Receiver Email:  ${information.ReceiverEmail}<br><br>
        <b>Message</b><br><br>
        ${body}
  `;

  return template;
};

exports.TemplateCorrectionRequestEmailContent = (cadTemp) => {
  if (typeof cadTemp != "object") return;
  const initial = "0000000";
  const cutLength = cadTemp.dataLength[0].DataLength;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${cadTemp.dataLength[0].DataLength}`;
  return `
          To ${cadTemp.Receiver} 様<br> <br>
          
          問い合わせ番号(inquiry reference number)：${referenceNumber}<br>
          問い合わせ内容(Content):<br>${cadTemp.Content}<br><br>
          <br><br>  
          ${cadTemp.signature ? `HRD-CAD事務所 (${cadTemp.signature})` : ""}
          <br>
          ${cadTemp.sendEmail}
         
        `;  
        
};
exports.TemplateCorrectionRequestEmail = (cadTemp) => {
  console.log(cadTemp,"435")
  if (typeof cadTemp != "object") return;
  const initial = "0000000";
  const cutLength = cadTemp.dataLength[0].DataLength;
  
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${cadTemp.dataLength[0].DataLength}`;
  return `
          To ${cadTemp.Receiver} 様<br> <br>
          問い合わせ番号(inquiry reference number)：${referenceNumber}<br>
          問い合わせ内容(Content):<br>${cadTemp.Content}<br><br>
          
          <br><br>  
          ${cadTemp.signature ? `HRD-CAD事務所 (${cadTemp.signature})` : ""}
        
        `;  
        
};
// ${cadTemp.sendEmail}
//  ${
//   cadTemp.inquiryCode
// //  ? `${cadTemp.CustomerCode} ${cadTemp.customerName} 様<br><br>`
//   : ""
// }
//  問い合わせ番号(inquiry reference number)：${referenceNumber}<br><br>

// 問い合わせ内容(Content):
//         本メールの宛先に返信してください。<br> ${cadTemp.signature ? `宜しくお願い致します。<br><br>${cadTemp.signature}` : ""}
//            <span style="font-size: 12px;color:grey">(You can reply in the system or directly reply to email)</span><br>
//            <a href="${
//              DevType == "development"
//      //          ? "http://localhost:8080"
//                : "https://inquiry.hrd-s.com"
//            }/received/reply/${
//   data.ConfirmationID
// }"> HRD Inquiry System </a><br><br></br>




//Template for FeedbackPage
exports.feedbackTemplate = async (data) => {
  console.log(data,"Template")
  if (typeof data != "object") return;
  // const referenceNumber ='123'
  const initial = "0000000";
  const cutLength = data.dataLength;
  const referenceNumber = `${moment().format("YYYYMMDD")}-${initial.slice(
    0,
    -cutLength
  )}${data.dataLength}`;
  return `
          問い合わせ番号(inquiry reference number)：${referenceNumber}<br>

          フィードバック数(Feedback Number) : ${data.FeedbackNo}<br><br>
          要求タイプ(Request Type) : ${data.RequestType}<br><br>

          Trial(Content):<br>${data.RequestDetails}<br><br>
          ${
            data.Attachments
              ? `添付ファイル(Attachment) : ${data.Attachments}<br><br>`
              : ``
          }
          <span style="font-size: 12px;color:grey">(You have a request Notification, You can check the feedback system to reply.)</span><br>
             <a href="${
               DevType == "development"
                 ? "http://localhost:8080"
                 : "https://inquiry.hrd-s.com"
             }/feedback/}
             "> HRD Inquiry System </a>
        `;
};
exports.feedbackReplyTemplate = async (data) => {
  // console.log(data,"Template")
  if (typeof data != "object") return;
  return `
          You have a message reply to your request (${data.RequestDetails})
          ${
            data.ReplyDetails
              ? `返信の詳細(Reply Details) : ${data.ReplyDetails}<br><br>`
              : ``
          }
          ${
            data.ReplyAttachments == 'null/'
              ? ``
              : `返信添付ファイルreply attachment) : ${data.ReplyAttachments}<br><br>`
          }
          <span style="font-size: 12px;color:grey">(You can check the feedback in the system. This email is a reply notification)</span><br>
             <a href="${
               DevType == "development"
                 ? "http://localhost:8080"
                 : "https://inquiry.hrd-s.com"
             }/feedback/}
             "> HRD Inquiry System </a>
        `;
};